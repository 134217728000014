import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { containerCss } from "../helpers/style";

const SectionMargin = ({
  height,
  color,
  fullWidth,
}: GatsbyTypes.SectionMarginFragmentFragment) => {
  return (
    <Wrapper
      $height={height as number}
      $color={color as string}
      $fullWidth={fullWidth ?? false}
    />
  );
};

export default SectionMargin;

export const fragment = graphql`
  fragment SectionMarginFragment on ContentfulSectionMargin {
    height
    color
    fullWidth
  }
`;

type WrapperProps = {
  $fullWidth: boolean;
  $height: number;
  $color: string;
};

const Wrapper = styled.div<WrapperProps>`
  ${containerCss}
  ${(props) =>
    props.$fullWidth &&
    css`
      max-width: none;
    `}
  height: ${(props) => props.$height}px;
  position: relative;
  z-index: -2;
  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;
